import gql from 'graphql-tag'

export const STORE_THEME = gql`mutation storeTheme ($input: StoreThemeInput!) {
    storeTheme (input: $input) {
        id
    }
}
`

export const THEME = gql`query theme ($domain_id: ID!) {
    themes(domain_id: $domain_id) {
        id
        domain {
            id
        }
        active
        dark
        preferred
        colors {
            primary
            secondary
            accent
            error
            info
            success
            warning
        }
        variables {
            background
            sheetBackground
            text
        }
        logo {
            id
            url
        }
        app_bar_logo {
            id
            url
        }
        welcome_image {
            id
            url
        },
        favicon {
            id
            url
        }
        font {
            id
            url
        }
        font_headings {
            id
            url
        }
        app_modules {
            key
            file {
                id
                url
            }
        }
    }
}
`
